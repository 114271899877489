import { NgModule } from '@angular/core';
import { AppearDirective } from './directives/appear.directive';
import { PricingDetailsComponent } from './components/pricing-details/pricing-details.component';
import { ConstantMaterialModule } from '../material_module/constant-material.module';

@NgModule({
  declarations: [AppearDirective],
  exports: [AppearDirective],
  imports : [ConstantMaterialModule]
})
export class SharedModule {}
