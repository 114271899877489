<section class="main_section" [ngStyle]="{'background-color': bgColor}">
    <div class="main-header-section">
        <div class="main-header-heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value}}
        </div>
        <div class="main-header-sub-heading" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
    </div>
    <div class="main-middle-section">
        <div class="left_container">
            <div [ngStyle]="data[3].style?data[3].style:{}">
                {{data[3].value}} :
            </div>
            <div class="heading">
                <a [ngStyle]="data[4].value[0].style?data[4].value[0].style:{}" (click)="redirectionUrl()" target="_blank" style="cursor:pointer">
                    {{data[4].value[0].value}}
                </a>
            </div>
            <div [ngStyle]="data[5].style?data[5].style:{}" style="margin-top: 10px;">
                {{data[5].value}}
            </div>
            <div [ngStyle]="data[6].style?data[6].style:{}" (click)="redirectMobile()" style="cursor:pointer">
                {{data[6].value}}
            </div>
            <div [ngStyle]="data[7].style?data[7].style:{}" style="margin-top: 10px;">
                {{data[7].value}}
            </div>
            <div [ngStyle]="data[8].style?data[8].style:{}" (click)="redirectEmail()" style="cursor:pointer">
                {{data[8].value}}
            </div>
            <!-- <div class="description">
            </div> -->
            <div class="form_section"></div>
        </div>
        <div class="right_container">
            <div class="flex_row">
                <div class="name_input">
                    <div class="label">Name</div>
                    <input placeholder="Please enter your name">
                </div>
                <div class="name_input">
                    <div class="label">Email</div>
                    <input placeholder="Please enter your mail id">
                </div>
            </div>
            <div class="flex_row_message">
                <div class="label">Message</div>
                <textarea name="" id="" cols="" rows="" placeholder="Enter message"
                    class="text_box"></textarea>
            </div>
            <div class="button_section">
                <button [ngStyle]="data[2].style?data[2].style:{}" class="send_btn" (click)="showMessage()">{{data[2].value}}</button>
            </div>
        </div>
    </div>
</section>