import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-our-contact-us-page',
  templateUrl: './our-contact-us-page.component.html',
  styleUrls: ['./our-contact-us-page.component.scss']
})
export class OurContactUsPageComponent implements OnInit{

  constructor(
    private title:Title,
    private metaTagService: Meta
  ){

  }

  ngOnInit(){
    this.title.setTitle('Contact Us');
    this.metaTagService.updateTag({name:'description',content:'Please contact us if you have any queries'})
  }

}
