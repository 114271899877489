import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preview-blog-cards-vertical-align',
  templateUrl: './blog-cards-vertical-align.component.html',
  styleUrls: ['./preview-blog-cards-vertical-align.component.scss']
})
export class PreviewBlogCardsVerticalAlignComponent {
  constructor(private router:Router){
  }
  @Input() blogData:any="";
  templateData: any = {
    'componentCategory': "blog-listing",
    'ranking': 0,
    'businessCategoryId': "",
    "businessCategoryName": "All category",
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "BlogListing1",
    "data" : [
      {
        value:"Latest from our blog",
        type:"Text",
        field:"Text",
        style:{"font-weight": 600}
      },
      {
        value:"Create custom landing pages with Rareblocks that converts more visitors than any website.",
        type:"Text",
        field:"Text",
        style:{"color":"#7D7D7C", "padding": "10px 0px"}
      },
      {
        value:"How a visual artist redefine success in graphic design",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "18px", "width":"80%"}
      },
      {
        value:"Why choose a theme that looks good with WooCommerce",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "17px", "width":"78%"}
      },
      {
        value:"How to write content about your photographs",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "17px", "width":"78%"}
      },
      {
        value:"Lessons and insights from 8 years of Pixelgrade",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "17px", "width":"78%"}
      },
      {
        value:"Travelling as a way of self-discovery and progress",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "17px", "width":"78%"}
      },
      {
        value:"The unseen of spending three years of Pixelgrade",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500, "font-size": "17px", "width":"78%"}
      },
      {
        value:"Growth .",
        type:"Text",
        field:"Text",
        style:{"color":"#7D7D7C","font-size":"14px", "font-weight":500}
      },
      {
        value:"April 09, 2022",
        type:"Text",
        field:"Text",
        style:{"color":"#7D7D7C","font-size":"14px", "font-weight":500}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      }
    ]
  }
  data = this.templateData.data
  heading = this.data[0]
  description = this.data[1]
  image1 = this.data[10]
  image2 = this.data[11]
  image3 = this.data[12]
  image4 = this.data[13]
  image5 = this.data[14]
  image6 = this.data[15]
  image1Content = this.data[2]
  image2Content = this.data[3]
  image3Content = this.data[4]
  image4Content = this.data[5]
  image5Content = this.data[6]
  image6Content = this.data[7]
  growth = this.data[8]
  dateOfImage = this.data[9]

  viewBlogPage(blog:any){
    this.router.navigate(['/blog'],{queryParams:{blogId:blog.id}});
    }

}
