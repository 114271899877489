import { Component } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss','./mq-features.component.scss']
})
export class FeaturesComponent {

}
