import { Component, HostListener, OnInit, Input} from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-contact-us3',
  templateUrl: './contact3.component.html',
  styleUrls: ['./contact3.component.css','./mq-contact3.component.css']
})
export class ContactUs3Component implements OnInit{

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }

  @Input() templateData:any = {
    "componentCategory" : "contact us",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/person.jpg",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'100%','height':'100%'}
      },
      {
        value : "Get In Touch",
        field : 'heading',
        type : 'heading',
        style : {'font-size':'32px','font-weight':700,'margin-bottom':'15px'}
      },
      {
        value : "Our friendly team would love to hear from you",
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'20px','color':'#959393','margin-bottom':'20px'}
      },
      {
        value : "First Name",
        type : 'subtext',
        field : 'subtext',
        style : {}
      },
      {
        value : "Last Name",
        type : 'subtext',
        field : 'subtext',
        style : {}
      },
      {
        value : "Email",
        type : 'subtext',
        field : 'subtext',
        style : {'width':'89%'}
      },
      {
        value : "Phone Number",
        type : 'subtext',
        field : 'subtext',
        style : {'width':'91%','height':'40px','display':'flex','border':'1px solid #959393','border-radius':'5px','margin-top':'5px'}
      },
      {
        value : "Message",
        type : 'subtext',
        field : 'subtext',
        style : {'width':'90%','height':'100px'}
      },
      {
        value : "You agree to our friendly privacy policy",
        type : 'text',
        field : 'text',
        style : {'width':'20px','height':'20px','margin-right':'10px','cursor':'pointer'}
      },
      {
        value : "Send Message",
        type : 'button',
        link : "",
        field : 'button',
        display : true,
        style : {'border-radius':'8px','background-color':'#A084DC','width':'90%','height':'30px','text-align':'center',
                  'margin-top':'30px','color':'#ffffff','font-size':'18px','padding-top':'14px','cursor':'pointer'}
      },
      {
        value : ['US','AUS'],
        type : 'text',
        field : 'text',
        style : {}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Contact3Component"
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[1];
  image = this.data[0];
  subtext = this.data[2];
  label1 = this.data[3];
  label2 = this.data[4];
  label3 = this.data[5];
  label4 = this.data[6];
  label5 = this.data[7];
  checkbox = this.data[8];
  submit = this.data[9];
  countryCode = this.data[10];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-contact-us3'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[1];
    this.image = this.data[0];
    this.subtext = this.data[2];
    this.label1 = this.data[3];
    this.label2 = this.data[4];
    this.label3 = this.data[5];
    this.label4 = this.data[6];
    this.label5 = this.data[7];
    this.checkbox = this.data[8];
    this.submit = this.data[9];
    this.countryCode = this.data[10];
  }

  screenWidth:any;
  @HostListener('window:resize',['$event'])
    getScreenWidth(){
      this.screenWidth = window.innerWidth;
    }
  
}
