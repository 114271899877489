import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';

import { NgSwitcheryModule } from 'angular-switchery-ios';

import { WebsiteRoutingModule } from './website-routing.module';
import { PreviewComponent } from './preview/preview.component';
import { ConstantMaterialModule } from './../../../material_module/constant-material.module';
import { FormsModule } from '@angular/forms';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SeoComponent } from './seo/seo.component';
import { SettingsComponent } from './settings/settings.component';
import { EditWebsiteComponent } from './edit-website/edit-website/edit-website.component';
import { AddComponentsectionComponent } from './edit-website/website-generationcomponent/add-componentsection/add-componentsection.component';
import { ContactUs1Component } from './edit-website/website-generationcomponent/contact-us/contact-us1/contact-us1.component';
import { ContactUs2Component } from './edit-website/website-generationcomponent/contact-us/contact-us2/contact-us2.component';
import { ContactUs3Component } from './edit-website/website-generationcomponent/contact-us/contact-us3/contact3.component';
import { ContactUs4Component } from './edit-website/website-generationcomponent/contact-us/contact-us4/contact4.component';
import { ListBlogsComponent } from './edit-website/website-generationcomponent/blogs/list-blogs/list-blogs.component';
import { CreateBlogsComponent } from './edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { ViewBlogsComponent } from './edit-website/website-generationcomponent/blogs/view-blogs/view-blogs.component';
import { MetaTagsPopupComponent } from './edit-website/website-generationcomponent/blogs/meta-tags-popup/meta-tags-popup.component';
import { DeleteBlogComponent } from './edit-website/website-generationcomponent/blogs/delete-blog/delete-blog.component';
import { BlogListingComponent } from './edit-website/website-generationcomponent/blogs/websiteComponents/blog-listing/blog-listing.component';
import { ListTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/list-tags/list-tags.component';
import { AddTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/add-tags/add-tags.component';
import { ViewTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/view-tags/view-tags.component';
import { DeleteTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/delete-tags/delete-tags.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ListAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/list-author/list-author.component';
import { AddAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/add-author/add-author.component';
import { DeleteAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/delete-author/delete-author.component';
import { ViewAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/view-author/view-author.component';
import { CreateMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/create-master-blogs/create-master-blogs.component';
import { ListMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/list-master-blogs/list-master-blogs.component';
import { BlogCardsVerticalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-vertical-align/blog-cards-vertical-align.component';
import { BlogLeftTextSideCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-left-text-side-cards/blog-left-text-side-cards.component';
import { BlogCardsHorizontalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-horizontal-align/blog-cards-horizontal-align.component';
import { BlogTagCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-tag-cards/blog-tag-cards.component';
import { PreviewBlogCardsVerticalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-vertical-align/preview-blog-cards-vertical-align.component';
import { PreviewBlogCardsHorizontalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-horizontal-align/preview-blog-cards-horizontal-align.component';
import { PreviewBlogTagCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-tag-cards/preview-blog-tag-cards.component';
import { BlogTextCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-text-cards/blog-text-cards.component';
import { PreviewBlogTextCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-text-cards/preview-blog-text-cards.component';
import { PreviewBlogLeftTextSideCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-left-text-side-cards/preview-blog-left-text-side-cards.component';
import { ViewMasterBlogComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/view-master-blog/view-master-blog.component';
import { ContactusTextInputsComponent } from './edit-website/website-generationcomponent/contact-us/contactus-text-inputs/contactus-text-inputs.component';
import { PreviewContactUs1Component } from './edit-website/website-generationcomponent/contact-us/contact-us1/preivew-contact-us1.component';
import { PreviewContactusTextInputsComponent } from './edit-website/website-generationcomponent/contact-us/contactus-text-inputs/preview-contactus-text-inputs.component';
import { ContactLeadsComponent } from './contact-leads/contact-leads.component';
import { BlogPageComponent } from './edit-website/website-generationcomponent/blogs-components/blog-page/blog-page.component';
import { ConnectExistingDomainComponent } from './popups/connect-existing-domain/connect-existing-domain.component';
import { StepsToFollowComponent } from './popups/steps-to-follow/steps-to-follow.component';
import { ProcessInprogressComponent } from './popups/process-inprogress/process-inprogress.component';
import { ListSearchedDomainsComponent } from './popups/list-searched-domains/list-searched-domains.component';
import { UpgradePlanComponent } from './popups/upgrade-plan/upgrade-plan.component';
import { ContactUsWithSocialLinksComponent } from './edit-website/website-generationcomponent/contact-us/contact-us-with-social-links/contact-us-with-social-links.component';
import { TextElementComponent } from './edit-website/v-2/elements/text-element/text-element.component';
import { ButtonElementComponent } from './edit-website/v-2/elements/button-element/button-element.component';
import { HorizontalCardComponent } from './edit-website/v-2/elements/service_section_cards/horizontal-card/horizontal-card.component';
import { VerticleCardComponent } from './edit-website/v-2/elements/service_section_cards/verticle-card/verticle-card.component';
import { HoverCardsComponent } from './edit-website/v-2/elements/service_section_cards/hover-cards/hover-cards.component';
import { SparkImageCardComponent } from './edit-website/v-2/elements/process_section_cards/spark-image-card/spark-image-card.component';
import { NumberTextCardComponent } from './edit-website/v-2/elements/process_section_cards/number-text-card/number-text-card.component';
import { ProcessCountImageSideCardComponent } from './edit-website/v-2/elements/process_section_cards/process-count-image-side-card/process-count-image-side-card.component';
import { SimpleTextProcessCardComponent } from './edit-website/v-2/elements/process_section_cards/simple-text-process-card/simple-text-process-card.component';
import { SimpleTestimonialCardComponent } from './edit-website/v-2/elements/testimonial_cards/simple-testimonial-card/simple-testimonial-card.component';
import { SingleTestimonialCardComponent } from './edit-website/v-2/elements/testimonial_cards/single-testimonial-card/single-testimonial-card.component';
import { SingleHorizontalTestimonialCardComponent } from './edit-website/v-2/elements/testimonial_cards/single-horizontal-testimonial-card/single-horizontal-testimonial-card.component';
import { FaqCardComponent } from './edit-website/v-2/elements/faq-card/faq-card.component';
import { V2EditorComponent } from './edit-website/v-2/editor/v2-editor/v2-editor.component';
import { TextEditorCardComponent } from './edit-website/v-2/editor/editor-components/text-editor-card/text-editor-card.component';
import { ContactusEditorCardComponent } from './edit-website/v-2/editor/editor-components/contact-us-card/contactus-editor-card.component';
import { ButtonEditorCardComponent } from './edit-website/v-2/editor/editor-components/button-editor-card/button-editor-card.component';
import { ImageEditorCardComponent } from './edit-website/v-2/editor/editor-components/image-editor-card/image-editor-card.component';
import { EditorRightSidePanelComponent } from './edit-website/v-2/editor/editor-components/editor-right-side-panel/editor-right-side-panel.component';
import { PlanButtonElementComponent } from './edit-website/v-2/elements/plan-button-element/plan-button-element.component';
import { HorizontalServiceDetailsComponent } from './edit-website/v-2/details-components/service-details/horizontal-service-details/horizontal-service-details.component';
import { CustomTextElementComponent } from './edit-website/v-2/custom-elements/custom-text-element/custom-text-element.component';
import { CustomButtonElementComponent } from './edit-website/v-2/custom-elements/custom-button-element/custom-button-element.component';
import { ListCustomElementsComponent } from './edit-website/v-2/list-custom-elements/list-custom-elements.component';
import { CustomHorizontalImageTextCardComponent } from './edit-website/v-2/custom-elements/custom-horizontal-image-text-card/custom-horizontal-image-text-card.component';
import { InputElementComponent } from './edit-website/v-2/elements/input-element/input-element.component';
import { DropdownElementComponent } from './edit-website/v-2/elements/dropdown-element/dropdown-element.component';
import { TextAreaElementComponent } from './edit-website/v-2/elements/text-area-element/text-area-element.component';
import { TermsPrivacyCardComponent } from './edit-website/v-2/details-components/terms-privacy-card/terms-privacy-card.component';
import { CustomTextOnImageElementComponent } from './edit-website/v-2/custom-elements/custom-text-on-image-element/custom-text-on-image-element.component';
import { NavbarEditorCardComponent } from './edit-website/v-2/editor/editor-components/navbar-editor-card/navbar-editor-card.component';
import { MobileEditorRightSidePanelComponent } from './edit-website/v-2/editor/editor-components/editor-right-side-panel/mobile-editor-right-side-panel.component';
import { NavbarImageEditorCardComponent } from './edit-website/v-2/editor/editor-components/navbar-image-editor-card/navbar-image-editor-card.component';
import { DynamicItemPopupComponent } from './edit-website/v-2/editor/editor-components/dynamic-item-popup/dynamic-item-popup.component';
import { ContactusDetailsEditCardComponent } from './edit-website/v-2/editor/editor-components/contactus-details-edit-card/contactus-details-edit-card.component';
import { WarningPopupComponent } from './edit-website/v-2/editor/popups/warning-popup/warning-popup.component';
import { CustomImageElementComponent } from './edit-website/v-2/custom-elements/custom-image-element/custom-image-element.component';
import { CustomMultiImageElementComponent } from './edit-website/v-2/custom-elements/custom-multi-image-element/custom-multi-image-element.component';
import { ContactUsDomainComponent } from './popups/contact-us-domain/contact-us-domain.component';
import { AboutUsPageComponent } from './edit-website/v-2/details-components/detail-abous-us/about-us-page/about-us-page.component';
import { AboutUsHerobannerComponent } from './edit-website/v-2/details-components/detail-abous-us/components/about-us-herobanner/about-us-herobanner.component';
import { AboutusBusinessDescriptionComponent } from './edit-website/v-2/details-components/detail-abous-us/components/aboutus-business-description/aboutus-business-description.component';
import { AboutusSpecializationOurmissionComponent } from './edit-website/v-2/details-components/detail-abous-us/components/aboutus-specialization-ourmission/aboutus-specialization-ourmission.component';
import { DeletePopComponent } from './edit-website/v-2/editor/popups/delete-pop/delete-pop.component';
import { ContactUsLocationComponent } from './edit-website/v-2/details-components/details-contact-us/components/contact-us-location/contact-us-location.component';
import { ContactUsInputTextComponent } from './edit-website/v-2/details-components/details-contact-us/components/contact-us-input-text/contact-us-input-text.component';
import { ServiceSectionCardsComponent } from './edit-website/v-2/details-components/details-service-section/components/service-section-cards/service-section-cards.component';
import { ServiceSectionProcessComponent } from './edit-website/v-2/details-components/details-service-section/components/service-section-process/service-section-process.component';
import { ServiceSectionContactComponent } from './edit-website/v-2/details-components/details-service-section/components/service-section-contact/service-section-contact.component';
import { BannerContentMiddleComponent } from './edit-website/v-2/elements/banner-content-middle/banner-content-middle.component';
import { HoverButtonElementComponent } from './edit-website/v-2/elements/hover-button-element/hover-button-element.component';
import { InputColouredElementComponent } from './edit-website/v-2/elements/input-coloured-element/input-coloured-element.component';
import { TextCardComponent } from './edit-website/v-2/elements/text-card/text-card.component';
import { SharedModule } from 'src/app/shared-module-files/shared.module';
import { ScreenLoaderComponent } from 'src/app/shared-module-files/components/screen-loader/screen-loader.component';
import { BlogFilterComponent } from './edit-website/website-generationcomponent/blogs/blog-filter/blog-filter.component';
import { AnimeLoaderComponent } from './anime-loader/anime-loader.component';

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { LinkDomainComponent } from './popups/link-domain/link-domain.component';
import { AdditionalComponentsComponent } from './additional-components/additional-components.component';
import { RatinCardComponent } from './edit-website/v-2/editor/editor-components/ratin-card/ratin-card.component';
import { BgImageEditorComponent } from './edit-website/v-2/editor/editor-components/bg-image-editor/bg-image-editor.component';
import { WebsitePreviewComponent } from './edit-website/v-2/editor/website-preview/website-preview.component';
// import { MatFormFieldModule } from '@angular/material/form-field';
import { MediaSelectorComponent } from './edit-website/v-2/editor/popups/media-selector/media-selector.component';
import { BlogPluginComponent } from './edit-website/website-generationcomponent/blogs/blog-plugin/blog-plugin.component';
import { ConfirmationFilterComponent } from './edit-website/website-generationcomponent/blogs/confirmation-filter/confirmation-filter.component';
import { V3GlobalStylesComponent } from './edit-website/v-2/editor/editor-components/v3-global-styles/v3-global-styles.component';
import { V3EditorComponent } from './edit-website/v-2/editor/v3-editor/v3-editor.component';
import { FloatingEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/floating-editor/floating-editor.component';
import { ContentEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/content-editor/content-editor.component';
import { StyleEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/style-editor/style-editor.component';
import { ButtonEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/button-editor/button-editor.component';
import { ImageEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/image-editor/image-editor.component';
import { ListItemComponentComponent } from './edit-website/v-2/editor/editor-compnents-v3/list-item-component/list-item-component.component';
import { QuillModule } from 'ngx-quill';
import { BackgroundColorEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/background-color-editor/background-color-editor.component';
import { BackgroundImageEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/background-image-editor/background-image-editor.component';
import { BorderEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/border-editor/border-editor.component';
import { ButtonStyleEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/button-style-editor/button-style-editor.component';
import { V3WidgetsComponent } from './edit-website/v-2/editor/popups/v3-widgets/v3-widgets.component';
import { V3ViewSiteComponent } from './edit-website/v-2/editor/popups/v3-view-site/v3-view-site.component';
import { BgColorPaletteComponent } from './edit-website/v-2/editor/bg-color-palette/bg-color-palette.component';
import { GlobalButtonStylesComponent } from './edit-website/v-2/editor/global-button-styles/global-button-styles.component';
import { PageListComponent } from './edit-website/v-2/editor/page-list/page-list.component';
import { OtherStylesComponent } from './edit-website/v-2/editor/other-styles/other-styles.component';
import { PageEditComponent } from './edit-website/v-2/editor/page-edit/page-edit.component';
import { LogoEditorComponent } from './edit-website/v-2/editor/editor-compnents-v3/logo-editor/logo-editor.component';
import { AddSocialChannelsComponent } from './edit-website/v-2/editor/editor-compnents-v3/add-social-channels/add-social-channels.component';
import { ContactFormEditorComponent } from './edit-website/v-2/editor/contact-form-editor/contact-form-editor.component';
import { AddContactFieldsComponent } from './edit-website/v-2/editor/add-contact-fields/add-contact-fields.component';
import { CreateNewPageComponent } from './edit-website/v-2/editor/create-new-page/create-new-page.component';
import { CreateFolderComponent } from './edit-website/v-2/editor/editor-compnents-v3/create-folder/create-folder.component';
import { ImageLoadingComponent } from './image-loading/image-loading.component';
import { MultiItemCarouselComponent } from './edit-website/v-2/editor/editor-compnents-v3/multi-item-carousel/multi-item-carousel.component';
import { WebsiteThemesComponent } from './website-themes/website-themes.component';
import { DividerListComponent } from './edit-website/v-2/editor/editor-compnents-v3/divider-list/divider-list.component';
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    PreviewComponent,
    AnalyticsComponent,
    SeoComponent,
    SettingsComponent,
    EditWebsiteComponent,
    AddComponentsectionComponent,
    ContactUs1Component,
    ContactUs2Component,
    ViewBlogsComponent,
    ContactUs3Component,
    ContactUs4Component,
    ListBlogsComponent,
    CreateBlogsComponent,
    MetaTagsPopupComponent,
    DeleteBlogComponent,
    BlogListingComponent,
    ListTagsComponent,
    AddTagsComponent,
    ViewTagsComponent,
    DeleteTagsComponent,
    ListAuthorComponent,
    AddAuthorComponent,
    DeleteAuthorComponent,
    ViewAuthorComponent,
    ListMasterBlogsComponent,
    BlogCardsVerticalAlignComponent,
    BlogTextCardsComponent,
    BlogCardsHorizontalAlignComponent,
    BlogTagCardsComponent,
    ContactusTextInputsComponent,
    BlogLeftTextSideCardsComponent,
    CreateMasterBlogsComponent,
    PreviewBlogCardsVerticalAlignComponent,
    PreviewBlogCardsHorizontalAlignComponent,
    PreviewBlogTagCardsComponent,
    PreviewBlogTextCardsComponent,
    PreviewBlogLeftTextSideCardsComponent,
    PreviewContactUs1Component,
    PreviewContactusTextInputsComponent,
    ListMasterBlogsComponent,
    ViewMasterBlogComponent,
    ListMasterBlogsComponent,
    ViewMasterBlogComponent,
    ContactLeadsComponent,
    BlogPageComponent,
    ConnectExistingDomainComponent,
    StepsToFollowComponent,
    ProcessInprogressComponent,
    ListSearchedDomainsComponent,
    UpgradePlanComponent,
    ContactUsWithSocialLinksComponent,
    TextElementComponent,
    ButtonElementComponent,
    HorizontalCardComponent,
    VerticleCardComponent,
    HoverCardsComponent,
    SparkImageCardComponent,
    NumberTextCardComponent,
    ProcessCountImageSideCardComponent,
    SimpleTextProcessCardComponent,
    SimpleTestimonialCardComponent,
    SingleTestimonialCardComponent,
    SingleHorizontalTestimonialCardComponent,
    FaqCardComponent,
    V2EditorComponent,
    TextEditorCardComponent,
    ContactusEditorCardComponent,
    ButtonEditorCardComponent,
    ImageEditorCardComponent,
    EditorRightSidePanelComponent,
    PlanButtonElementComponent,
    HorizontalServiceDetailsComponent,
    CustomTextElementComponent,
    CustomButtonElementComponent,
    ListCustomElementsComponent,
    CustomHorizontalImageTextCardComponent,
    InputElementComponent,
    DropdownElementComponent,
    TextAreaElementComponent,
    TermsPrivacyCardComponent,
    CustomTextOnImageElementComponent,
    NavbarEditorCardComponent,
    MobileEditorRightSidePanelComponent,
    NavbarImageEditorCardComponent,
    DynamicItemPopupComponent,
    ContactusDetailsEditCardComponent,
    WarningPopupComponent,
    CustomImageElementComponent,
    CustomMultiImageElementComponent,
    ContactUsDomainComponent,
    AboutUsPageComponent,
    AboutUsHerobannerComponent,
    AboutusBusinessDescriptionComponent,
    AboutusSpecializationOurmissionComponent,
    DeletePopComponent,
    ContactUsLocationComponent,
    ContactUsInputTextComponent,
    ServiceSectionCardsComponent,
    ServiceSectionProcessComponent,
    ServiceSectionContactComponent,
    BannerContentMiddleComponent,
    HoverButtonElementComponent,
    InputColouredElementComponent,
    TextCardComponent,
    BlogFilterComponent,
    AnimeLoaderComponent,
    LinkDomainComponent,
    AdditionalComponentsComponent,
    RatinCardComponent,
    BgImageEditorComponent,
    WebsitePreviewComponent,
    MediaSelectorComponent,
    BlogPluginComponent,
    ConfirmationFilterComponent,
    V3GlobalStylesComponent,
    V3EditorComponent,
    FloatingEditorComponent,
    ContentEditorComponent,
    StyleEditorComponent,
    ButtonEditorComponent,
    ImageEditorComponent,
    ListItemComponentComponent,
    BackgroundColorEditorComponent,
    BackgroundImageEditorComponent,
    BorderEditorComponent,
    ButtonStyleEditorComponent,
    V3WidgetsComponent,
    V3ViewSiteComponent,
    BgColorPaletteComponent,
    GlobalButtonStylesComponent,
    PageListComponent,
    OtherStylesComponent,
    PageEditComponent,
    LogoEditorComponent,
    AddSocialChannelsComponent,
    ContactFormEditorComponent,
    AddContactFieldsComponent,
    CreateNewPageComponent,
    CreateFolderComponent,
    ImageLoadingComponent,
    MultiItemCarouselComponent,
    WebsiteThemesComponent,
    DividerListComponent,
  ],
  imports: [
    QuillModule.forRoot(
      {
        theme:'snow',
        modules: {
          // Include toolbar module
          syntax: true,  // Include syntax module if needed
          // Add other modules as needed
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image', 'video'],
            [{ 'color': ['#E04181', '#11668C','color-picker'] }]
          ],
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: (range: any, context: any) => {
                  return true;
                }
              }
            }
          }
        }
      }
    ),
    CommonModule,
    WebsiteRoutingModule,
    ConstantMaterialModule,
    FormsModule,
    MatExpansionModule,
    MatSliderModule,
    NgSwitcheryModule,
    SharedModule,
    ScreenLoaderComponent,
    LottieModule.forRoot({ player: playerFactory }),
    // MatFormFieldModule,
    // AppModule
  ],
  exports: [
    AnimeLoaderComponent,
    TextCardComponent,

    // blog cards section
    BlogCardsVerticalAlignComponent,
    // BlogLeftTextSideCardsComponent,
    BlogTextCardsComponent,
    BlogCardsHorizontalAlignComponent,
    BlogTagCardsComponent,

    //contact us
    ContactusTextInputsComponent,
    ContactUs1Component,
    ContactUs2Component,
    ContactUs3Component,
    ContactUs4Component,
    ContactUsWithSocialLinksComponent,

    //blog components in website
    PreviewBlogCardsVerticalAlignComponent,
    PreviewBlogCardsHorizontalAlignComponent,
    PreviewBlogTagCardsComponent,
    PreviewBlogTextCardsComponent,
    PreviewBlogLeftTextSideCardsComponent,

    //contact us
    PreviewContactusTextInputsComponent,
    PreviewContactUs1Component,
    TextEditorCardComponent,
    ContactusEditorCardComponent,
    HorizontalServiceDetailsComponent,
    CustomTextElementComponent,
    CustomHorizontalImageTextCardComponent,
    TermsPrivacyCardComponent,
    CustomTextOnImageElementComponent,
    ContactUsDomainComponent,
    AboutUsHerobannerComponent,
    AboutusBusinessDescriptionComponent,
    AboutusSpecializationOurmissionComponent,
    ContactUsInputTextComponent,
    ContactUsLocationComponent,
    ServiceSectionCardsComponent,
    ServiceSectionContactComponent,
    ServiceSectionProcessComponent,
  ],
  providers: [DatePipe],
})
export class WebsiteModule {}
