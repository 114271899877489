<section class="main_section" [ngStyle]="{'background-color': bgColor}">
    <div class="main-header-section">
        <div class="main-header-heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value}}
        </div>
        <div class="main-header-sub-heading" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
    </div>
    <div class="main-middle-section">
        <div class="left_container">
            <div class="flex_row">
                <div class="name_input">
                    <div class="label">Name</div>
                    <input placeholder="Please enter your name">
                </div>
                <div class="name_input">
                    <div class="label">Email</div>
                    <input placeholder="Please enter your mail id">
                </div>
            </div>
            <div class="flex_row_phone">
                <div class="label">Phone</div>
                <input placeholder="Please enter your phone number">
            </div>
            <div class="flex_row_message">
                <div class="label">Message</div>
                <textarea name="" id="" cols="" rows="" placeholder="Enter message"
                    class="text_box"></textarea>
            </div>
            <div class="button_section">
                <button [ngStyle]="data[2].style?data[2].style:{}" class="send_btn" (click)="showMessage()">{{data[2].value}}</button>
            </div>
        </div>
        <div class="right_container">
            <!-- <div class="form_section"></div> -->
            <div style="display: flex;align-items:flex-start">
                <div style="display:flex;justify-content: center;align-items: flex-start;">
                    <img src="./../../../../../../../../assets/images/common/location.png" alt="Location" style="width:20px;background-color: white;">
                </div>
                <div style="margin-left:14px">
                    <div [ngStyle]="data[3].style?data[3].style:{}">{{data[3].value}}</div>
                    <div style="cursor:pointer">
                        <a [ngStyle]="data[4].value[0].style?data[4].value[0].style:{}" (click)="redirectionUrl()" target="_blank" style="text-decoration: underline;">
                            {{data[4].value[0].value}}
                        </a>
                    </div>
                </div>
            </div>
            <div style="display:flex;align-items:flex-start;margin-top: 15px">
                <div style="display:flex;justify-content: center;align-items: flex-start;">
                    <img src="./../../../../../../../../assets/images/common/dialer-phone.png" alt="" style="width:20px">
                </div>
                <div style="margin-left:14px">
                    <div [ngStyle]="data[5].style?data[5].style:{}">{{data[5].value}}</div>
                    <div [ngStyle]="data[6].style?data[6].style:{}" (click)="redirectMobile()" style="cursor:pointer">
                        {{data[6].value}}
                    </div>
                </div>
            </div>
            <div style="display:flex;align-items: flex-start;margin-top: 15px;">
                <div style="display:flex;justify-content: center;align-items: flex-start;">
                    <img src="./../../../../../../../../assets/images/common/email.jpg" alt="" style="width:20px">
                </div>
                <div style="margin-left:14px">
                    <div [ngStyle]="data[7].style?data[7].style:{}">{{data[7].value}}</div>
                    <div [ngStyle]="data[6].style?data[6].style:{}" (click)="redirectEmail()" style="cursor:pointer">
                        {{data[8].value}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>