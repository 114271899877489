
<section class="main-section">
  <div class="d-flex justify-space">
    <p class="heading">Themes</p>
    <button class="store-btn" (click)="previewCurrentWebsite()"><mat-icon>remove_red_eye</mat-icon> View your store</button>
  </div>

  <div class="box-shadow">
    <img class="main-image" [src]="defualtTheme?.template?.screenShot || 'assets/images/website-themes/404341-PD3K72-786.jpg'" alt="">

    <div class="theme-cards">
      <div class="theme-left-side">
        <img [src]="defualtTheme?.template?.screenShot || 'assets/images/website-themes/404341-PD3K72-786.jpg'"  alt="">
        <div>
          <p class="current-theme-badge">Current theme</p>
          <p class="theme-name">{{defualtTheme.template.name}}</p>
          <p class="theme-date">Added: {{defualtTheme.createdDateTime | date}}</p>
        </div>
      </div>
      <div class="theme-right-side">
        <!-- <button class="button"><mat-icon>more_horiz</mat-icon></button> -->
        <button class="button" (click)="customizeThemes()">Customize</button>
      </div>
    </div>
  </div>

  <div class="box-shadow mt-20">
    <div class="p-15 border-bottom">
      <p class="theme-name">Theme Library</p>
      <p class="theme-date mb-0">These themes are only visible to you. You can switch to another theme by publishing it to your store.</p>
    </div>

    <div>
      <ng-container *ngFor="let data of businessthemes;let i = index">
        <div class="theme-cards border-bottom" *ngIf="!data.default">
          <div class="theme-left-side">
            <img [src]="data?.template?.screenShot || 'assets/images/website-themes/'"   alt="">
            <div>
              <p class="theme-name">{{data.template.name}}</p>
              <p class="theme-date">Added: {{data.createdDateTime | date}}</p>
            </div>
          </div>
          <div class="theme-right-side">
            <button class="button" [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="previewTheme(data.id)">Preview</button>
              <button mat-menu-item (click)="deleteTheme(data.id)">Remove</button>
            </mat-menu>


            <button class="button" (click)="publishWebsite(data)" *ngIf="!data.publish">Publish</button>
            <button class="button" *ngIf="data.publish"><mat-spinner style="height: 20px;width: 20px;"></mat-spinner></button>
            <button class="button" (click)="customizeThemes(data.id)">Customize</button>
          </div>
        </div>
      </ng-container>
      <div class="no-data-found" *ngIf="!isUnDefaultThemeAvailable">
        <p>No Themes added</p>
      </div>
    </div>
  </div>

  <div class="box-shadow mt-20">
    <div class="p-15 border-bottom">
      <p class="theme-name">Popular free themes</p>
      <p class="theme-date mb-0">Made with core features you can easily customize—no coding needed.</p>
    </div>

    <div class="row theme-library p-15">
      <div class="col-md-4 col-12 position-relative" *ngFor="let template of templateLibrary">
        <div class="theme-library-bs">
          <img  [src]="template.screenShot || 'assets/images/website-themes/404341-PD3K72-786.jpg'" alt="">
          <div class="d-flex justify-space p-15">
            <div>
              <p class="theme-name">{{template.name}}</p>
              <p class="theme-date mb-0 ">By Simpo</p>
            </div>
            <div>
              <button class="button" (click)="addTheme(template)" *ngIf="!template.delete">Add</button>
              <button class="button" *ngIf="template.delete"><mat-spinner style="height: 20px;width: 20px;"></mat-spinner></button>
            </div>
          </div>
        </div>
        <div class="premium-text" *ngIf="template.templateCategory === 'PREMIUM'">Premium</div>
      </div>
    </div>
  </div>
</section>
