import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { decode, isBlurhashValid } from "blurhash";

@Component({
  selector: "image-loading",
  templateUrl: "./image-loading.component.html",
  styleUrls: ["./image-loading.component.scss"],
})
export class ImageLoadingComponent {
  @Input() hash: string | null = null;
  @Input() image: HTMLImageElement | undefined;

  @ViewChild("canvas")
  private canvas: ElementRef<HTMLCanvasElement> | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.image, "display", "none");
  }

  ngAfterViewInit(): void {
    if (this.hash && this.canvas && this.image) {
      if (isBlurhashValid(this.hash).result) {
        const pixels = decode(this.hash, 300, 300);
        const ctx = this.canvas.nativeElement.getContext("2d");
        if (ctx) {
          const imageData = ctx.createImageData(300, 300);
          imageData.data.set(pixels);
          ctx.putImageData(imageData, 0, 0);
        }
      }
      this.image.addEventListener("load", () => this.onImageLoad());
    }
  }

  ngOnDestroy(): void {
    if (this.image) {
      this.image.removeEventListener("load", this.onImageLoad);
    }
  }

  private onImageLoad() {
    this.renderer.setStyle(this.image, 'display', 'block');
    this.renderer.setStyle(this.canvas?.nativeElement, 'display', 'none');
  }
}
