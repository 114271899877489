import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-blog-text-cards',
  templateUrl: './blog-text-cards.component.html',
  styleUrls: ['./preview-blog-text-cards.component.scss']
})
export class PreviewBlogTextCardsComponent {

    templateData: any = {
      'componentCategory': "blog-listing",
      'ranking': 0,
      'businessCategoryId': "",
      "businessCategoryName": "All category",
      "createdTimeStamp": "",
      "language": "English",
      "componentName": "BlogListing4",
      "data" : [
        {
          value:"Read Latest Articles",
          type:"Text",
          field:"Text",
          style:{"font-weight": 700}
        },
        {
          value:"Create custom landing pages with Rareblocks that converts more visitors than any website",
          type:"Text",
          field:"Text",
          style:{"color":"#656769"}
        },
        {
          value:"How to write content about your photographs",
          type:"Text",
          field:"Text",
          style:{"font-weight":500}
        },      
        {
          value:"Lorem ipsum dolor sit amet. Consectetur adipiscing elit.",
          type:"Text",
          field:"Text",
          style:{"color":"#7d7d7c"}
        },      
        {
          value:"Growth .",
          type:"Text",
          field:"Text",
          style:{}
        },
        {
          value:"April 09, 2022",
          type:"Date",
          field:"Text",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        },
        {
          imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
          type:"Image",
          field:"Image",
          style:{}
        }
      ]
    }
  
    data = this.templateData.data
    heading = this.data[0]
    description = this.data[1]
    question = this.data[2]
    answer = this.data[3]
    growthText = this.data[4]
    date = this.data[5]
    image1 = this.data[6]
    image2 = this.data[7]
    image3 = this.data[8]
    image4 = this.data[9]
    image5 = this.data[10]
    image6 = this.data[11]
  
  }
