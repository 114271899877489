import { Component, OnInit } from '@angular/core';
import { WebisiteService } from '../webisite.service';

@Component({
  selector: 'app-contact-leads',
  templateUrl: './contact-leads.component.html',
  styleUrls: ['./contact-leads.component.scss', './mq-contact-leads.component.scss']
})
export class ContactLeadsComponent implements OnInit{

  constructor(
    private _websiteService : WebisiteService
  ){}

  allLeads: any = [];
  businessId : any;

  ngOnInit(): void {
      let businessDetails = window.localStorage.getItem('bDetails') || '';
      this.businessId = JSON.parse(businessDetails).id
      this.getAllContacts(this.businessId);
  }

  getAllContacts(data:any){
    this._websiteService.getContactLeads(data).subscribe(
      (res:any) => {
        //console.log(res.data);
        this.allLeads = res.data;
      },
      (err) => {
        //console.log(err);
      }
    )
  }
}
