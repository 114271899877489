import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<UpgradePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _paymentService : PaymentsService,
    private _snackbar : MatSnackBar,
    private _dbService : DbService
  ){}

  gettingLoader : boolean = false;

  ngOnInit(): void {
    this.getSubscriptionPlans();
  }

  upgradePlan : any;

  getSubscriptionPlans(){
    this.gettingLoader = true;
    this._paymentService.getPlansBasedOnBusinessId(this._dbService.getBusinessId()).subscribe(
      (res:any) => {
        console.log(res.data);
        res.data.forEach(element => {
          if(element.prices.monthlyPrice > 0){
            this.upgradePlan = element; 
          }
        });
        this.gettingLoader = false;
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.gettingLoader = false;
      }
    )
  }

  isAnually : boolean = true;
  twoButtons = [
    {
      name:'monthly',
      status:false,
      value:'MONTHLY'
    },
    {
      name:'yearly',
      status: true,
      value:'ANNUALLY'
    }
  ];
  close(){
    this.dialogRef.close();
  }
  planChange(a:any){
    this.twoButtons.forEach(tb => {
      if(a === tb.value){
        tb.status = true;
      }
      else{
        tb.status = false;
      }
    });
    if(a === 'ANNUALLY'){
      this.isAnually = true;
    }
    else{
      this.isAnually = false;
    }
  }

  loader : boolean = false;

  planUpgration(id){
    this.loader = true;
    let data : any  = {
      businessId : this._dbService.getBusinessId(),
      subscriptionId:id,
      isMonthly:!this.isAnually,
      domain:`${environment.redirectingUrl}`,
      affiliatePartnerKey : ""
    }
    if(localStorage.getItem("partnerId"))
      {
        data.affiliatePartnerKey = localStorage.getItem("partnerId")
      }

    this._paymentService.subscribePlan(data).subscribe(
      (res:any) => {
        window.location.href = `${res.data.url}`
        this._dbService.setPaymentId(res.data.paymentId);
        window.localStorage.setItem('endDate',res.data.endDate);
        window.localStorage.setItem('planName',res.data.planName);
        window.localStorage.setItem('planAmount',res.data.planAmount);
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
      }
    )
  }
}
