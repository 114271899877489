import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

import {GeoJson} from './../constants/map';
import * as mapboxgl from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private http: HttpClient,
  ) { 
    let a = mapboxgl.accessToken
    //  environment.mapbox.accessToken;
    if(!a) return;
    a = environment.mapbox.accessToken;
  }

  getAddress(lat:any,lng:any){
    return this.http.get(
      `https://api.tiles.mapbox.com/v4/geocode/mapbox.places-v1/${lng},${lat}.json?access_token=${environment.mapbox.accessToken}`
    )
  }
  searchAddress(search_text:any){
    return this.http.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json?access_token=${environment.mapbox.accessToken}`
    )
  }
}
