import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { TemplateService } from 'src/app/services/template.service';
import { PricingDetailsComponent } from 'src/app/shared-module-files/components/pricing-details/pricing-details.component';

@Component({
  selector: 'app-website-themes',
  templateUrl: './website-themes.component.html',
  styleUrls: ['./website-themes.component.scss']
})
export class WebsiteThemesComponent {

  constructor(private templateService: TemplateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private _eventHelper: EventEmmiterService
  ){}

  businessDetails: any;
  websiteUrl: any = '';
  websiteStatus: any;
  currentPlanDetails: any;

  ngOnInit() {
    let bDetails = window.localStorage.getItem('bDetails');

    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }
    this.getBusinessDetailsByBusinessId();
    this.getTemplateLibrary();
    this.getBusinessThemes();
    this.getCurrentPlan();
    this.switchBusiness();
  }

  ngOnDestroy() {
    this.currentPlanSubscription?.unsubscribe();
    this.updatePlanSubscription?.unsubscribe();
    this.switchBusinessSubscription?.unsubscribe()
  }

  currentPlanSubscription?: Subscription;
  getCurrentPlan() {
    this.currentPlanSubscription?.unsubscribe();
    this.currentPlanSubscription = this.subscriptionService.getCurrentPlanDetails().subscribe((res: any) => {
      this.currentPlanDetails = res;
      console.log(this.currentPlanDetails)
    })
  }

  getBusinessDetailsByBusinessId() {
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res: any) => {
        this.websiteStatus = res.data.website.status;
        this.websiteUrl = res.data.website.domainVerified ? res.data.website.domainUrl : res.data.website.websiteUrl;
      },
      (err) => {
        this.templateService.openSnack(
          `${err.error.message}`, 'Close'
        )
      }
    )
  }


  templateLibrary: any[] = [];
  getTemplateLibrary() {
    this.templateService.getTemplateLibrary().subscribe((res: any) => {
      this.templateLibrary = res.data;
      this.templateLibrary?.forEach((res: any) => {
        res['delete'] = false;
      })
    })
  }

  businessthemes: any[] = [];
  defualtTheme: any;
  isUnDefaultThemeAvailable: boolean = false;
  getBusinessThemes() {
    this.templateService.getBusinessThemes().subscribe((res: any) => {
      this.businessthemes = res.data;

      this.businessthemes?.forEach((theme: any) => {
        theme['publish'] = false;
        if(theme.default)
          this.defualtTheme = theme;
        else
          this.isUnDefaultThemeAvailable = true;
      })
    })
  }

  addTheme(template) {
    template.delete = true;

    if(this.checkPlanPermission(template.templateCategory)) {
      this.subscriptionService.openDialog(PricingDetailsComponent, '100vh', '100vw', {});
      template.delete = false;
      return;
    }

    let payload = {
      "businessId": this.businessDetails.id,
      "businessName": this.businessDetails.name,
      "templateId": template.id,
      "templateName": template.name,
      "subIndustryId": this.businessDetails.subIndustryId,
      "subIndustryName": this.businessDetails.subIndustryName,
      "templateImage" : template.screenShot,
      "templateType" : template.type,
      "templateCategory" : template.templateCategory
    }

    this.templateService.addToBusinessLibrary(payload).subscribe((res: any) => {
      template.delete = false;
      this.snackBar.open('Theme added successfully', 'close', {duration: 2000});
      this.updateCurrentPlan(template.templateCategory == 'FREE' ? "FREE_THEMES" : "PREMIUM_THEMES");
      this.getBusinessThemes();
    }, () => {
      template.delete = false;
    })
  }

  switchBusinessSubscription?: Subscription;
  switchBusiness() {
    this.switchBusinessSubscription?.unsubscribe();
    this.switchBusinessSubscription = this._eventHelper.switchBusiness.subscribe(
      (res: any) => {
        if (res.switchBusiness) {
          this.ngOnInit();
        }
      }
    )

  }

  publishWebsite(template: any) {
    template.publish = true;
    this.templateService.publishTemplate(template.id).subscribe((res: any) => {
      template.publish = false;
      this.getBusinessThemes();
      this.businessDetails.templateId = res.data;
      localStorage.setItem('bDetails', JSON.stringify(this.businessDetails));
    }, () => {
      template.publish = false;
    })
  }

  deleteTheme(templateId: string) {
    this.templateService.deleteTheme(templateId).subscribe((res: any) => {

      let index = -1;
      this.businessthemes?.forEach((theme: any, i) => {
        if(theme.id === templateId)
          index = i;
      })

      if(index != -1)
        this.businessthemes.splice(index, 1);

      this.snackBar.open('Theme deleted successfully', 'close', {duration: 2000});
    })
  }

  customizeThemes(templateId?: string) {
    this.router.navigate(['/website-editor'] , {queryParams:{templateId:templateId, status:this.websiteStatus}});
  }

  previewTheme(templateId: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/website-preview'], { queryParams: { templateId: templateId } })
    );
    window.open(url, '_blank');
  }

  previewCurrentWebsite() {
    window.open(this.websiteUrl, '_blank')?.focus();
  }

  checkPlanPermission(categoryType: string) {
    categoryType = categoryType == 'FREE' ? "FREE_THEMES" : "PREMIUM_THEMES";

    for(let subModule of this.currentPlanDetails.featureLimit['WEBSITE']) {
      if(subModule.subModuleName === categoryType) {
        if(subModule.limitCount == 0) {
          return true;
        } else {
          subModule.limitCount--;
          break;
        }
      }
    }

    return false;
  }

  updatePlanSubscription?: Subscription;
  updateCurrentPlan(subModule: any) {
    this.updatePlanSubscription?.unsubscribe()
    this.updatePlanSubscription = this.subscriptionService.updatePlan('WEBSITE', subModule).subscribe();
  }

}
