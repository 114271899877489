import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-our-hero-banner',
  templateUrl: './our-hero-banner.component.html',
  styleUrls: ['./our-hero-banner.component.scss','./mq-our-hero-banner.component.scss']
})
export class OurHeroBannerComponent {

  constructor(
    private router : Router,
    private _dbService : DbService,
    private templateService:TemplateService,
  ) {}

  ngOnInit(): void {
    if(this._dbService.getBusinessId() != null){
      this.router.navigate([`/admin/website/preview`]);
    }
    else{}
  }

  go(){
    // this.router.navigate(['/introduction'])
    this.router.navigate([`/business-type`]);
  }
  
  redirectToCalendly(){
    window.open('https://calendly.com/simpo-ai','_blank')
  }

}
