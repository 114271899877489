import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preview-blog-cards-horizontal-align',
  templateUrl: './blog-cards-horizontal-align.component.html',
  styleUrls: ['./preview-blog-cards-horizontal-align.component.scss']
})
export class PreviewBlogCardsHorizontalAlignComponent {
  constructor(private router:Router){
  }
  @Input() blogData:any="";
  templateData: any = {
    'componentCategory': "blog-listing",
    'ranking': 0,
    'businessCategoryId': "",
    "businessCategoryName": "All category",
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "BlogListing5",
    "data" : [
      {
        value:"Read Latest Collection",
        type:"Text",
        field:"Text",
        style:{"font-weight": 700}
      },
      {
        value:"Create custom landing pages with Rareblocks that converts more visitors than any website",
        type:"Text",
        field:"Text",
        style:{"color":"#a8a7a3"}
      },
      {
        value:"Growth",
        type:"Text",
        field:"Text",
        style:{"background-color":"white", "font-weight": 500}
      },
      {
        value:"April 09, 2022",
        type:"Text",
        field:"Text",
        style:{"color":"#969696", "padding-bottom":"20px"}
      },
      {
        value:"How a visual artist redefines success in graphic design",
        type:"Text",
        field:"Text",
        style:{"font-weight":500}
      },
      {
        value:"Continue reading ➔",
        type:"Text",
        field:"Text",
        style:{}
      }
      // {
      //   imgUrl:"./../../assets/images/ed-tech.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // },
      // {
      //   imgUrl:"./../../assets/images/insurance.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // },
      // {
      //   imgUrl:"./../../assets/images/financial-service.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // }
    ]
  }

  data = this.templateData.data
  heading = this.data[0]
  description = this.data[1]
  growthText = this.data[2]
  date = this.data[3]
  graphicDesignText = this.data[4]
  continueReading = this.data[5]
  // image1 = this.data[6]
  // image2 = this.data[7]
  // image3 = this.data[8]

  viewBlogPage(blog:any){
    this.router.navigate(['/blog'],{queryParams:{blogId:blog.id}});
    }

}
