<section class="main-section">
    <div class="main-responsive-section">
        <div class="left-side">
            <div class="main-heading">
                Access anytime & Anywhere.
            </div>
            <div class="sub-heading">
                Simpo.ai can be adapt to all devices. You can always manage your business all the time.
            </div>
        </div>
        <div class="right-side">
            <div class="right-side-responsive">

            </div>
            <div class="right-side-image">
                <img src="./../../../../assets/images/common/table_image.svg" alt="table_svg" />
            </div>
        </div>
    </div>
</section>
