import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-website-type',
  templateUrl: './website-type.component.html',
  styleUrls: ['./website-type.component.scss']
})
export class WebsiteTypeComponent {
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute
  ){}

  activatedRouteSubscription?: Subscription;
  ngOnInit() {
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((param: any) => {
      localStorage.setItem('planId', param.id);
    })
  }

  ngOnDestroy() {
    this.activatedRouteSubscription?.unsubscribe();
  }

  websiteType!: 'STATIC' | 'E_COMMERCE';
  next(){
    localStorage.setItem(StorageKeys.WEBSITE_TYPE,this.websiteType);
    this.router.navigate(['/business-type'])
  }
  active_ecommerce: boolean=false;
  active_informational:boolean=false;
  activeClass()
  {
    this.active_ecommerce=true;
    this.active_informational=false;
  }
  activeClass1()
  {
    this.active_ecommerce=false;
    this.active_informational=true;
  }


}
