<div class="mainSection">
    <div class="headPart">
        <div [ngStyle]="heading.style?heading.style:{}" class="heading">{{heading.value}}</div>
        <!-- <div [ngStyle]="description.style?description.style:{}" class="description">{{description.value}}</div> -->
    </div>
    <div class="blogsContainer">
        <ng-container *ngFor="let blog of blogData;let i =index">
            <ng-container *ngIf="i<=2">
                <div class="blog">   
                    <div class="bg1" 
                    [style.background]="blog.blocks[0].type=='image'?'url('+blog.blocks[0].data.file.url+')':'url(../../../../../../../../assets/images/blog-website/blog-listing.jpg)'">
                        <div [ngStyle]="growthText.style?growthText.style:{}" class="growthText">{{blog?.postTitle}}</div>
                    </div>
                    <div [ngStyle]="date.style?date.style:{}" class="dateStyle">{{blog?.createdTimeStamp|date:'mediumDate'}}</div>
                    <div [ngStyle]="graphicDesignText.style?graphicDesignText.style:{}" class="graphicDesignText">{{blog?.postSummary}}</div>
                    <div class="continue-reading-section">
                    <div [ngStyle]="continueReading.style?continueReading.style:{}" class="continueReading" (click)="viewBlogPage(blog)">{{continueReading.value}}</div>
                    </div>
                
            </div>
            </ng-container>
        </ng-container>
        
        
        <!-- <div class="blog">
            <div class="bg2">
                <div [ngStyle]="growthText.style?growthText.style:{}" class="growthText">{{blogData[1]?.postTitle}}</div>
            </div>
            <div [ngStyle]="date.style?date.style:{}" class="dateStyle">{{blogData?.createdTimeStamp|date:'mediumDate'}}</div>
            <div [ngStyle]="graphicDesignText.style?graphicDesignText.style:{}" class="graphicDesignText">{{blogData?.postSummary}}</div>
            <div [ngStyle]="continueReading.style?continueReading.style:{}" class="continueReading">{{continueReading.value}}</div>
        </div>
        <div class="blog">
            <div class="bg3">
                <div [ngStyle]="growthText.style?growthText.style:{}" class="growthText">{{blogData?.postTitle}}</div>
            </div>
            <div [ngStyle]="date.style?date.style:{}" class="dateStyle">{{blogData?.createdTimeStamp|date:'mediumDate'}}</div>
            <div [ngStyle]="graphicDesignText.style?graphicDesignText.style:{}" class="graphicDesignText">{{blogData?.postSummary}}</div>
            <div [ngStyle]="continueReading.style?continueReading.style:{}" class="continueReading">{{continueReading.value}}</div>
        </div> -->
    </div>
</div>