<section class="total-container">
  <div>
    <div class="container">
      <div>
        <div>
          <div class="heading text-center">Upgrade version</div>
        </div>
        <div class="close-window" (click)="closeWindow()"><mat-icon>close</mat-icon></div>
        <div class="buttons_bar">
          <button *ngFor="let item of twoButtons" (click)="changeSubscriptionType(item)" [ngClass]="{'activate': subscriptionType === item.value}"
            >{{item.name | titlecase}}
            <!-- <span class="offers_tag" *ngIf="item.value === 'ANNUALLY'">
              <img src="./../../../assets/images/common/offer_tag.svg" alt="offer_tag"> Get 2 months free
            </span> -->
          </button>
        </div>
        <div class="row">
          <ng-container *ngFor="let plan of planList">
            <div class="col-md-4 col-12 mt-5" [ngClass]="{'mt-0': plan.plan.recommended === true}" *ngIf="plan.plan.id != '1efa980b-8bf0-6c12-9b62-639b28d6a93d'">
              <div class="individual-options" [ngClass]="{'recommended-individual-options': plan.plan.recommended === true}">
                <div class="pricing-header">
                  <div *ngIf="plan.plan.recommended === true" class="recommended">Recommended</div>
                  <div class="plan-name">{{plan.plan.planName}}</div>
                  <div class="plan-price" *ngIf="plan.prices.yearlySellingPrice > 0">
                    ₹ {{subscriptionType == 'MONTHLY' ? plan.prices.monthlySellingPrice :
                    plan.prices.yearlySellingPrice}} <span class="span-tag">{{ subscriptionType === 'MONTHLY' ? '/per month' : '/per year' }}</span>
                  </div>
                  <div class="plan-desc mt-20" *ngIf="plan.prices.yearlySellingPrice == 0">Contact us for a customized pricing plan tailored to your business needs</div>
                </div>
                <div class="pricing-content">
                  <div class="plan-desc">{{plan.plan.description}}</div>

                  <div>
                    <button class="button" (click)="upgradePlan(plan)" [ngClass]="{'recommended-btn': plan.plan.recommended === true}" *ngIf="plan.prices.yearlySellingPrice > 0">Get started</button>
                    <button class="button" (click)="contactUs()" *ngIf="plan.prices.yearlySellingPrice == 0">Contact us</button>
                  </div>
                  <div class="plan-name">Features you'll have</div>
                  <div class="body-desc content-options">
                    <div class="d-flex gap-15 features" *ngFor="let content of plan.features">
                      <img
                        src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/619936c1732360867923Check%20circle.png"
                        alt=""> {{content}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
