import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit{

  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router,
    private _snackBar : MatSnackBar
  ){
  }

  planName : any = '';
  planAmount : any = '';
  validity : any = '';

  isDomainPayment: boolean = false;
  ngOnInit(): void {
    if(this._router.url.includes('domain-success')) {
      this.isDomainPayment = true;
    }

    this.planName = window.localStorage.getItem('planName');
    this.planAmount = window.localStorage.getItem('planAmount');
    this.validity = window.localStorage.getItem('endDate');

    let paymentId = this.isDomainPayment ? localStorage.getItem('domainPaymentId') : this._dbService.getPaymentId();
    this.sendingStatus(paymentId)
  }

  sendingStatus(pId){
    let data = {
      paymentId : pId,
      status:'SUCCESS',
      isDomainMapping: this.isDomainPayment
    }
    this._paymentService.sendPaymentId(data).subscribe(
      (res:any) => {
        localStorage.removeItem('pId');
        localStorage.removeItem('planName');
        localStorage.removeItem('planAmount');
        localStorage.removeItem('endDate');
        localStorage.removeItem('domainPaymentId');
        this.getBusinessDetails();
      },
      (err) => {
        console.log(err);
        // this.getBusinessDetails();
        this._router.navigate(['/admin/account/subscriptions']);
      }
    )
  }

  getBusinessDetails(){
    this._paymentService.getDetailsByBusinessId(window.localStorage.getItem('businessId')).subscribe(
      (res:any) => {
        window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
        this._router.navigate(['/admin/account/subscriptions']);
      },
      (err) => {
        // console.log(err)
      }
    )
  }

}
