<section class="main-section">
    <div class="main-heading">
        Trusted & Growing their Business
    </div>

    <div class="main-testimonial-section">
        <div class="testimonial-section">
            <div class="main-text">
                This website builder has been a lifesaver for my small business. I now have a professional-looking website that's helped me attract new clients and grow my brand
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/first.svg" alt="person" />
                </div>
                <div class="text-section">
                    Rajveer mishra
                </div>
            </div>
        </div>
        <div class="testimonial-section">
            <div class="main-text">
                I've never been very tech-savvy, but this website builder made it easy for me to create a professional website without any prior experience
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/second.svg" alt="person" />
                </div>
                <div class="text-section">
                    Sarah John
                </div>
            </div>
        </div>
        <div class="testimonial-section">
            <div class="main-text">
                Thanks to this website builder, I now have a website that's mobile-friendly and responsive. My customers can access my site from anywhere and on any device.
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/third.svg" alt="person" />
                </div>
                <div class="text-section">
                    Mason Cooper
                </div>
            </div>
        </div>
        <div class="testimonial-section">
            <div class="main-text">
                I couldn't believe how easy it was to build a website with Simpo.ai. The AI-powered builder made the process a breeze
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/fourth.svg" alt="person" />
                </div>
                <div class="text-section">
                    Riyaz mohammed
                </div>
            </div>
        </div>
        <div class="testimonial-section">
            <div class="main-text">
                The templates available with this website builder are stunning. I was able to find one that perfectly matched my brand and vision, and the customization options made it even better.
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/fivth.svg" alt="person" />
                </div>
                <div class="text-section">
                    Chloe Foster
                </div>
            </div>
        </div>
        <div class="testimonial-section">
            <div class="main-text">
                This website builder has been a true partner in my business's success. It's given me the tools and resources I need to create a website that attracts and retains customers.
            </div>
            <div class="image-text-section">
                <div class="image-section">
                    <img src="./../../../../assets/images/testimonial/sixth.svg" alt="person" />
                </div>
                <div class="text-section">
                    Chaitrika
                </div>
            </div>
        </div>
    </div>
</section>