import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-previous-work',
  templateUrl: './previous-work.component.html',
  styleUrls: ['./previous-work.component.scss','./mq-previous-work.component.scss']
})
export class PreviousWorkComponent implements OnInit{

  constructor(
    private _router : Router
  ){}

  ngOnInit(): void {
  }

  generateWebsite(){
    this._router.navigate([`/business-type`]);
  }

}
