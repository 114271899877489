<div class="main-section">
    <div class="left">
        <div>
            <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
            <div [ngStyle]="subtext.style?subtext.style:{}">{{subtext.value}}</div>
        </div>
        <div>
            <div class="name">
                <div class="first-name">
                    <span>{{label1.value}}</span>
                    <input class="input" type="" placeholder="{{label1.value}}">
                </div>
                <div class="last-name">
                    <span>{{label2.value}}</span>
                    <input class="input" type="" placeholder="{{label2.value}}">
                </div>
            </div>
            <div>
                <span>{{label3.value}}</span><br>
                <input class="input" type="text" placeholder="you@company.com" [ngStyle]="label3.style?label3.style:{}">
            </div>
            <div>
                <span>{{label4.value}}</span><br>
                <div [ngStyle]="label4.style?label4.style:{}">
                    <select name="country" id="country-code">
                        <option value="{{countryCode.value[0]}}">{{countryCode.value[0]}}</option>
                        <option value="{{countryCode.value[1]}}">{{countryCode.value[1]}}</option>
                    </select>
                
                    <input type="" placeholder="+1(555)000-0000" class="phone" >
                </div>
            </div>
            <div>
                <span id="text-box">{{label5.value}}</span>
                <!-- <input type="text" [ngStyle]="label5.style?label5.style:{}"> -->
                <textarea name="" id="" cols="" rows="" [ngStyle]="label5.style?label5.style:{}"></textarea>
            </div>
            <div>
                <input type="checkbox" [ngStyle]="checkbox.style?checkbox.style:{}">
                <label for="">{{checkbox.value}}</label>
            </div>
            <div>
                <div [ngStyle]="submit.style?submit.style:{}">{{submit.value}}</div>
            </div>
        </div>
        
    </div>
    <div class="right" *ngIf="screenWidth > 475">
        <img src="{{image.imgUrl}}" alt="" [ngStyle]="image.style?image.style:{}">
    </div>
</div>