import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  setBusinessId(businessId: any) {
    localStorage.setItem('businessId', businessId);
  }
  getBusinessId() {
    return localStorage.getItem('businessId');
  }

  setbusinessAsPrivate(element:any){
    localStorage.setItem('private', element);
  }
  getbusinessAsPrivate(element:any){
    return localStorage.getItem('private');
  }

   
  // business Currency prefernces
  setBusinessPrefernce(currency: any) {
    localStorage.setItem('prefrences', currency);
  }
  getBusinessPrefernce() {
    return localStorage.getItem('prefrences');
  }

  // crm module
  setLeadDetails(element: any) {
    localStorage.setItem('leadDetails', JSON.stringify(element))
  }
  getLeadDetails() {
    let leadDetails: any = localStorage.getItem('leadDetails');
    return JSON.parse(leadDetails);
  }

  setFname(fname: any) {
    localStorage.setItem('fname', fname);
  }
  getFname() {
    return localStorage.getItem('fname')
  }

  //payment id storing

  setPaymentId(pId) {
    localStorage.setItem('pId', pId);
  }
  getPaymentId() {
    return localStorage.getItem('pId');
  }

  setOrderId(orId) {
    localStorage.setItem('orId', orId);
  }
  getOrderId() {
    return localStorage.getItem('orId');
  }

  setOrderPrice(orPrice) {
    localStorage.setItem('orP', orPrice);
  }
  getOrderPrice() {
    return localStorage.getItem('orP');
  }

  setAnuallyOrNot(isAnually) {
    localStorage.setItem('anually', isAnually);
  }
  getAnuallyOrNot() {
    return localStorage.getItem('anually');
  }

  setAnalyticsToken(data: any) {
    localStorage.setItem('analyticsToken', data);
  }

  getAnalyticsToken() {
    return localStorage.getItem('analyticsToken');
  }

  openDialog(dialog) {
    this.dialog.open(dialog);
  }


}
