import { Component, Input, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { ContactUsField, ContactUsModel } from 'simpo-component-library/lib/sections/contact-us/contact-us.modal';
import { TemplateService } from 'src/app/services/template.service';
import { AddContactFieldsComponent } from '../add-contact-fields/add-contact-fields.component';
import {CdkDragDrop, moveItemInArray, CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-contact-form-editor',
  templateUrl: './contact-form-editor.component.html',
  styleUrls: ['./contact-form-editor.component.scss']
})
export class ContactFormEditorComponent {

  @Input() contactField: ContactUsField = {
    customLabel: false,
    successMessage: '',
    fields: [],
    button: 'send'
  };

  constructor(private dialogService: TemplateService){}

  ngOnInit() {
   
    this.screenSize()
  }
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video'],
        [{ 'color': ['#E04181', '#11668C','color-picker'] }]
      ],

    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    }
  };

  screenWidth: any;
  @HostListener('window: resize',['$event'])
  screenSize(){
    this.screenWidth= window.innerWidth
  }

  manageFields() {
    if(this.screenWidth > 475){
      this.dialogService.openDialog(AddContactFieldsComponent, '', '30%', {data: this.contactField.fields})
    }
    if(this.screenWidth < 475){
      this.dialogService.openBottomSheet(AddContactFieldsComponent, {data: this.contactField.fields}, '')
    }
    
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.contactField?.fields, event.previousIndex, event.currentIndex);
  }

  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }
  canShowContactField(field) {
    return Object.hasOwn(field, "status") ? field.status : true;
  }
}
