import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-cards-horizontal-align',
  templateUrl: './blog-cards-horizontal-align.component.html',
  styleUrls: ['./blog-cards-horizontal-align.component.scss','./mq-blog-cards-horizontal-align.component.scss']
})
export class BlogCardsHorizontalAlignComponent {
  constructor(private router:Router){
  }
   @Input() blogData:any="";

   ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log("blogData",this.blogData);
    // this.checkingStyles()
   }

  templateData: any = {
    'componentCategory': "blog-listing",
    'ranking': 0,
    'businessCategoryId': "",
    "businessCategoryName": "All category",
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "BlogListing5",
    "data" : [
      {
        value:"Read Latest Collection",
        type:"text",
        field:"Text",
        style:{"font-weight": 700},
        textType:'heading'
      },
      {
        value:"Create custom landing pages with Rareblocks that converts more visitors than any website",
        type:"text",
        field:"Text",
        style:{"color":"#a8a7a3"}
      },
      {
        value:"Growth",
        type:"text",
        field:"Text",
        style:{"background-color":"white", "font-weight": 500}
      },
      {
        value:"April 09, 2022",
        type:"text",
        field:"Text",
        style:{"color":"#969696", "padding-bottom":"20px"}
      },
      {
        value:"How a visual artist redefines success in graphic design",
        type:"text",
        field:"Text",
        style:{"font-weight":500}
      },
      {
        value:"Continue reading ➔",
        type:"text",
        field:"Text",
        style:{}
      }
      // {
      //   imgUrl:"./../../assets/images/ed-tech.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // },
      // {
      //   imgUrl:"./../../assets/images/insurance.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // },
      // {
      //   imgUrl:"./../../assets/images/financial-service.jpg",
      //   type:"Image",
      //   field:"Image",
      //   style:{}
      // }
    ]
  }

  data = this.templateData.data
  heading = this.data[0]
  description = this.data[1]
  growthText = this.data[2]
  date = this.data[3]
  graphicDesignText = this.data[4]
  continueReading = this.data[5]
  // image1 = this.data[6]
  // image2 = this.data[7]
  // image3 = this.data[8]

  viewBlogPage(blog:any){
    // if(!blog.businessId){
    //   this.router.navigate(['/blog'],{queryParams:{blogId:blog.id}});
    // }
    // else{
      this.router.navigate(['/blog'],{queryParams:{blogId:blog.id,bId:blog.businessId,cId:blog.subIndustryId}})
    // }
  }

  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    // data.style = {
    //   "color":this.templateData.textColor,
    //   "font-family":this.templateData.fontFamily,
    // }
    // console.log(this.templateData);
    if(data.textType === 'heading'){
      data.style = {
        "color":this.templateData.colors.txtHeadingColor,
        "font-family":this.templateData.headingFontFamily,
      }
    }
    else if(data.textType === 'description'){
      data.style = {
        "color":this.templateData.colors.txtDescColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
    else if(data.textType === 'text'){
      data.style = {
        "color":this.templateData.colors.txtNormalColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":"none",
          "padding":"10px 25px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"10px 25px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "padding":"10px 25px",
          "height":"44px",
          "font-size":"16px"
          // "border-radius":"none"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"10px 25px",
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "height":"44px",
          "font-size":"16px"
        }
      }
    }
  }
}
