import { Component ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-componentsection',
  templateUrl: './add-componentsection.component.html',
  styleUrls: ['./add-componentsection.component.scss']
})
export class AddComponentsectionComponent {
constructor(
  public dialogRef: MatDialogRef<AddComponentsectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
){

}
closepopup(){
  this.dialogRef.close();
}
}
