import { Component } from '@angular/core';

@Component({
  selector: 'app-responsiveness',
  templateUrl: './responsiveness.component.html',
  styleUrls: ['./responsiveness.component.scss','./mq-responsiveness.component.scss']
})
export class ResponsivenessComponent {

}
