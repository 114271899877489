import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router
  ){}

  isDomainPayment: boolean = false;
  ngOnInit(): void {
    if(this._router.url.includes('domain-cancel')) {
      this.isDomainPayment = true;
    }

    let paymentId = this.isDomainPayment ? localStorage.getItem('domainPaymentId') : this._dbService.getPaymentId()
    this.sendingStatus(paymentId)
  }

  sendingStatus(pId){
    let data = {
      paymentId : pId,
      status:'CANCELED',
      isDomainMapping: this.isDomainPayment
    }
    this._paymentService.sendPaymentId(data).subscribe(
      (res:any) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err)
      }
    )
  }

  done(){
    this._router.navigate(['/admin/account/subscriptions'])
  }
}
