import { Component, HostListener, Inject, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import Swal from 'sweetalert2';
import { Observable, Subscription } from 'rxjs';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { error } from 'console';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

enum FormType {
  "SIGN_UP"="SIGN_UP",
  "LOGIN"="LOGIN"
}

@Component({
  selector: 'app-singup-popup',
  templateUrl: './singup-popup.component.html',
  styleUrls: ['./singup-popup.component.scss']
})
export class SingupPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<SingupPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any,
    private bottomSheetRef: MatBottomSheetRef<SingupPopupComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData:any,
    private authService: AuthServiceService,
    private templateService: TemplateService,
    private router : Router,
    private _dbService: DbService,
    private SocialAuthService: SocialAuthService,
    private staffService: StaffServiceService,
    private subscriptionService: SubscriptionService
  ){
    this.getAllCountries();
    this.triggerGoogleSignin();
    this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';
    const industry = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.selectedIndustry = industry ? JSON.parse(industry) : this.selectedIndustry;
    this.getScreenSize();
  }

  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcWidth = window.innerWidth;
  }

  onEmailChange(event) {
    this.signInForm.controls["email"].setValue(event.target.value.toLowerCase());
    this.signupForm.controls["email"].setValue(event.target.value.toLowerCase());
  }
  ngOnInin(){
  }

  private authSubscription: Subscription | undefined;
  formType: FormType = FormType.SIGN_UP;
  loggedIn:any
  signinHide = true;
  triggerGoogleSignin(){
    this.authSubscription = this.SocialAuthService.authState.subscribe((user) => {
      this.googleUser = user;
      this.loggedIn = (user != null);
      if(this.googleUser){
        this.login()
      }
    })
  }

  ngOnDestroy(){
    this.authSubscription?.unsubscribe();
  }

  textDescArray: any = [
    {
      title:'Website editor',
      description:"An easy to use editor tool to Customize Your Website's Look and Feel"
    },
    {
      title:'Custom domain name',
      description:"A powerful feature that lets you map with your own domain"
    },
    {
      title:'Website analytics',
      description:"Track and measure your website's performance.Gain valuable insights about audience's behavior"
    },
    {
      title:'SEO tools',
      description:"you can easily enhance your website's search engine visibility and attract organic traffic to your site."
    },
    {
      title:'Blogs',
      description:"Easily create and manage a blog section on your website with our intuitive blog editor"
    }
  ];

  openMailLogin(){
    let element = document.getElementById('google_autenticate');
    if(!element) return;
    element.click();
  }

  //signup
  signupForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    dialCode: new FormControl('', [Validators.required]),
    mobileNumber: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), this.validatePassword()]),
  });

  private validatePassword(): ValidatorFn  {
    return (c: AbstractControl): ValidationErrors | null => {
      const oneLetterCapital = new RegExp(/[A-Z]/g);
      const oneSymbolPattern = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g);
      const oneNumberPattern = new RegExp(/[0-9]/g);

      const errorObj = {
        // oneLetterCapital: oneLetterCapitalPattern.test(c.value),
        oneSymbolPattern: oneSymbolPattern.test(c.value),
        oneNumberPattern: oneNumberPattern.test(c.value),
        oneLetterCapital: oneLetterCapital.test(c.value),
        minimumLength: c.value.length >= 8
      }

      if (!errorObj.minimumLength || !errorObj.oneNumberPattern || !errorObj.oneSymbolPattern) {
        return errorObj;
      }
      console.log(this.signupForm.valid)

      return null;
    }
  }


  //signin
  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    rememberMe: new FormControl(false)
  });

  disableCountryCodeDropdown: boolean = false;
  getAllCountries() {
    this.authService.getAllCountries().subscribe(
      (res: any) => {
        this.countryCodes = res.data?.data ?? [];

        const shortCode = localStorage.getItem(StorageKeys.SHORT_CODE);

        let shortCodeIndex = -1;

        let dialCode;
        if(localStorage.getItem(StorageKeys.dialCodeDropdown) === 'true'){
          dialCode = true
        }
        else{
          dialCode = false
        }

        if (shortCode) {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === shortCode.toLowerCase())
        } else {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === 'in');
        }

        if (shortCodeIndex > -1) {
          this.selectedCountry = this.countryCodes[shortCodeIndex];
          this.signupForm.get('dialCode')?.setValue(this.countryCodes[shortCodeIndex].shortCode)
          this.disableCountryCodeDropdown = dialCode ? true : false;
        }
      },
      (err) => {
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close',
        )
      }
    )
  }
  countryCodes: any[] = [];
  selectedCountry: any;
  signupHide = true;
  showToolTip: boolean = false;
  hide() {
    this.showToolTip = false;
  }
  privacyPolicy() {
    // this.router.navigate(['/privacy-policy']);
    window.open(`${environment.redirectingOtherSite}/privacy-policy`,'_blank');
  }
  termsConditions() {
    // this.router.navigate(['/terms-conditions'])
    window.open(`${environment.redirectingOtherSite}/terms-conditions`,'_blank');
  }

  loader : boolean = false;
  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    industryName: '',
    subIndustryId: '',
    subIndustryName: ''
  }
  websiteId: string = '';
  googleUser:any;
  createAnAccount() {
    this.loader = true;

    let data : any = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      contact: {
        mobile: this.signupForm.value.mobileNumber,
        email: this.signupForm.value.email,
        countryCode: this.signupForm.value.dialCode,
        name: this.signupForm.value.name
      },
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY) ?? "Hyderabad",
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE) ?? "17.360589",
          longitude: localStorage.getItem(StorageKeys.LONGITUDE) ?? "78.474061"
        }
      },
      industryName: this.selectedIndustry.industryName,
      industryId: this.selectedIndustry.industryId,
      subIndustryId: this.selectedIndustry.subIndustryId,
      subIndustryName: this.selectedIndustry.subIndustryName,
      name: this.signupForm.value.name,
      pwd: this.signupForm.value.password,
      templateId: this.websiteId,
      countryId: this.selectedCountry.id,
      countryCode: this.selectedCountry.shortCode,
      websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS),
      affiliatePartnerKey: null,
    }
    if(localStorage.getItem("partnerId"))
    {
      data.affiliatePartnerKey = localStorage.getItem("partnerId")
    }
    this.authService.createAccount(data).subscribe(
      (res: any) => {
        this.closePopup();
        this._dbService.setBusinessId(res.data.business[0].id)
        this._dbService.setFname(res.data.name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        this.getCurrencySymbol(res.data.business[0].id);
        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId);
        // this.getStaffPermission(res.data.id, res.data.business[0].id);

        localStorage.setItem("countryCode", data.countryCode);
        localStorage.setItem("countryId", data.countryId);

        // const selectedBusinessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);

        // if (selectedBusinessName) {
        //   this.router.navigate(['v2-generator']);
        // } else {
          this.getBusinessDetailsById(res.data.business[0].id);
        // }
      },
      // (err) => {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: err.error.message
      //   });
      //   this.loader = false;
      // }
      (error: any) => {
        if (error.status === 409) {
          console.log("Email address already exists. Please use a different email.");
          this.openPopup();
        } else {
          console.error("An error occurred:", error);
        }
        this.loader = false;
      }
    )
  }
  openPopup() {
    const errorPopUp = document.getElementById('error');
    if(!errorPopUp) return;
    errorPopUp.style.display = "block";
    errorPopUp.style.backdropFilter = "brightness(0.3)"
  }

  closeWindow(){
    const errorPopUp = document.getElementById('error');
    if(!errorPopUp) return;
    errorPopUp.style.display = "none";
  }

  getBusinessDetailsById(bId: any) {
    this.authService.getDetailsByBusinessId(bId).subscribe(
      (res: any) => {
        window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
        window.localStorage.setItem('type', 'admin');
        this.getStaffPermission(localStorage.getItem(StorageKeys.STAFF_ID), bId, res.data.business.type);

      },
      (err) => {
        this.loader = false;
      }
    )
  }
  getCurrencySymbol(bId: any) {
    this.authService.getCurrencySymbol(bId).subscribe({
      next: (value: any) => {
        this._dbService.setBusinessPrefernce(JSON.stringify(value.data));
      }
    })
  }

  redirectToSignIn() {
    // this.router.navigate(['/login'])
    this.formType  = FormType.LOGIN;
  }

  redirectToSignup(){
    this.formType  = FormType.SIGN_UP;
  }

  closePopup(){
    if(this.srcWidth > 475){
      this.dialogRef.close({});
    }
    if(this.srcWidth < 475){
      this.bottomSheetRef.dismiss({});
    }
  }

  login() {
    var data;
    this.loader = true;
    if(this.googleUser){
      data = {
        businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
        contact:{
          email:this.googleUser.email
        },
        address: {
          city: localStorage.getItem(StorageKeys.LOCALITY),
          geolocation: {
            latitude: localStorage.getItem(StorageKeys.LATITUDE),
            longitude: localStorage.getItem(StorageKeys.LONGITUDE)
          }
        },
        industryName: this.selectedIndustry.industryName,
        industryId: this.selectedIndustry.industryId,
        subIndustryId: this.selectedIndustry.subIndustryId,
        subIndustryName: this.selectedIndustry.subIndustryName,
        userName: this.googleUser.email.toLowerCase(),
        name: this.googleUser.name,
        templateId: this.websiteId,
        countryId: this.selectedCountry?.id ?? '',
        countryCode: this.selectedCountry?.shortCode ?? '',
        googleToken: this.googleUser ? this.googleUser.idToken : null,
        websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS),
        affiliatePartnerKey : null
      }
    } else if (this.websiteId) {
      data = {
        businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
        address: {
          city: localStorage.getItem(StorageKeys.LOCALITY),
          geolocation: {
            latitude: localStorage.getItem(StorageKeys.LATITUDE),
              longitude: localStorage.getItem(StorageKeys.LONGITUDE)
          }
        },
        industryName: this.selectedIndustry.industryName,
        industryId: this.selectedIndustry.industryId,
        subIndustryId: this.selectedIndustry.subIndustryId,
        subIndustryName: this.selectedIndustry.subIndustryName,
        userName: this.signInForm.value.email.toLowerCase(),
        pwd: this.signInForm.value.password,
        templateId: this.websiteId,
        countryId: this.selectedCountry?.id ?? '',
        countryCode: this.selectedCountry?.shortCode ?? '',
        websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS)
      }
    } else {
      data = {
        userName: this.signInForm.value.email.toLowerCase(),
        pwd: this.signInForm.value.password,
      }
    }
    if(this.googleUser)
    {
    if(localStorage.getItem("partnerId"))
    {
      data.affiliatePartnerKey = localStorage.getItem("partnerId")
    }
    }
    this.authService.login(data).subscribe(
      (res: any) => {
        this.closePopup();
        if(res.data === 'New Google User Found'){
          localStorage.setItem(StorageKeys.GOOGLETOKENID,this.googleUser.idToken);
          localStorage.setItem("countryCode", this.selectedCountry?.shortCode);
          localStorage.setItem("countryId", this.selectedCountry?.id);
          this.router.navigate(['/business-type']);
          return;
        }

        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        this._dbService.setFname(res.data.name)
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business[0].name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId);

        // localStorage.setItem("countryCode", data.countryCode);
        // localStorage.setItem("countryId", data.countryId);

        // const selectedBusinessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);

        // if (selectedBusinessName) {
        //   this.router.navigate(['v2-generator']);
        // } else
        if (res.data.business.length === 1) {
          this._dbService.setBusinessId(res.data.business[0].id);
          this.getBusinessDetailsById(res.data.business[0].id);
          // this.getStaffPermission(res.data.id, res.data.business[0].id)
          this.getCurrencySymbol(res.data.business[0].id);
        } else {
          res.data.business.forEach(element => {
            if(((element.name).toLowerCase()).split(' ').join('_') === ((data.businessName).toLowerCase()).split(' ').join('_')){
              this.getBusinessDetailsById(element.id);
              // this.getStaffPermission(res.data.id, element.id)
              this.getCurrencySymbol(element.id);
              this._dbService.setBusinessId(element.id);
            }
            // else{
            //   element.favourite = false;
            // }
          });
          // let a = res.data.business.find(e => e.favourite);
        }

        if(this.signInForm.get('rememberMe')?.value){
          sessionStorage.setItem('eucpviqc',btoa((this.signInForm.value.email).toLowerCase()));
          sessionStorage.setItem('fox',btoa(this.signInForm.value.password));
          sessionStorage.setItem('remember',`${this.signInForm.get('rememberMe')?.value}`);
        }
        else{
          sessionStorage.setItem('remember',`${this.signInForm.get('rememberMe')?.value}`);
          sessionStorage.clear();
        }

      },
      (err) => {
        this.loader = false;
        this.templateService.openSnack(`${err.error.message}`, 'Close')
      }
    );
  }

  forgotPasword(){
    this.templateService.openDialog(
      ForgotPasswordComponent,
      'auto',
      '540px',
      '80vw'
    )
  }

  getStaffPermission(staffId: any, businessId: any, businessType: any){
    this.staffService.getStaffPermissionByStaffId(staffId, businessId).subscribe((res: any) => {
      localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data));

      this.subscriptionService.changeCurrentPlanDetails();
      this.subscriptionService.openPricingModal();

      if(!businessType || businessType == 'STATIC')
        this.router.navigate(['/admin/home']);
      else
        this.router.navigate(['/admin/products/list-products']);
      this.loader = false;
    }, (err) => {
      this.loader = false
    })
  }



}
