<section class="main_section">
    <!-- <div class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </div> -->
    <div class="body_section">
        <div class="left_container">
            <div class="label">
                Paste Share URL here
            </div>
            <div class="input-btn">
                <div class="input-field business-field-section">
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input placeholder="Use Share URL only" matInput [(ngModel)]="url"/>
                    </mat-form-field>
                </div>
                <div class="btn">
                    <button (click)="continue()"
                    [disabled]="url === ''">Continue</button>
                </div>
            </div>
        </div>
        <div class="right_container">

        </div>
    </div>
</section>