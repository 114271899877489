<section class="main-section">
    <div class="main-cta-section">
    <div class="main-left-text" (click)="redirectTo()">
        <!-- <span> -->
            Build your business website
        <!-- </span> -->
        <!-- <div class="unleash"> -->
            <!-- <ul class="unleash">
                <li>Unleash your business potential</li>
                <li>Unleash your business potential</li>
            </ul> -->
        <!-- </div> -->
    </div>
    <div class="main-right-text">
        with simpo.ai
    </div>
    </div>
</section>
