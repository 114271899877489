<div class="header-component">
    <div class="header-text">
        Add Section
    </div>
    <div class="close-image" (click)="closepopup()">
        X
    </div>
</div>
<hr class="hr-line"/>
<div class="blocks-text">
    Blocks
</div>

<div class="blocks-section">

<div class="right-section">
    <div class="inner-container">
        <div class="text-section">
            <div class="header-text">
                heading
            </div>  
            <div class="text-sectiom">
                text
            </div> 
        </div>
        <div class="image-section">
                image
        </div>
    </div>
   
</div>
<div class="right-section">
    <div class="inner-container">
        <div class="text-section">
            <div class="header-text">
                heading
            </div>  
            <div class="text-sectiom">
                text
            </div> 
        </div>
        <div class="image-section">
                image
        </div>
    </div>
   
</div>
<div class="right-section">
    <div class="inner-container">
        <div class="text-section">
            <div class="header-text">
                heading
            </div>  
            <div class="text-sectiom">
                text
            </div> 
        </div>
        <div class="image-section">
                image
        </div>
    </div>
   
</div>

</div>
