<section class="main_section">
    <div class="top_layer">
        <div class="layer_title">All Domains</div>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="hr_line"></div>
    <div class="custom_domains">
        <ng-container *ngFor="let item of allDomains">
            <div class="single_domain">
                <div class="check_img">
                    <img src="./../../../../../../assets/images/common/check.svg" alt="check_mark">
                </div>
                <div class="domain_url">
                    {{item.domain}}
                </div>
                <div class="status_btn">
                    <div class="public_status">&#x2022; Public</div>
                    <div class="get_btn">
                        <button>Get</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</section>