import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebisiteService } from '../../webisite.service';

@Component({
  selector: 'app-list-searched-domains',
  templateUrl: './list-searched-domains.component.html',
  styleUrls: ['./list-searched-domains.component.scss']
})
export class ListSearchedDomainsComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<ListSearchedDomainsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _websiteService : WebisiteService
  ){}

  allDomains : any = [];

  ngOnInit(): void {
    this.getAllDomains();
  }

  close(){
    this.dialogRef.close();
  }

  getAllDomains(){
    this._websiteService.getAllDomains(this.data).subscribe(
      (res:any) => {
        this.allDomains = res.data;
      },
      (err)=>{
        console.log(err)
      }
    )
  }

}
