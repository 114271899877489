import { Component } from '@angular/core';

@Component({
  selector: 'app-our-testimonial',
  templateUrl: './our-testimonial.component.html',
  styleUrls: ['./our-testimonial.component.scss','./mq-our-testimonial.component.scss']
})
export class OurTestimonialComponent {

}
