<section class="main-section">
    <div class="main-heading">
        A valuable tool in growing my online presence and driving business success.
    </div>
    <div class="sub-main-heading">
        As a small business owner, website builder has been a game-changer. It's incredibly user-friendly, allowing me to create and manage my website with ease. The customization options for design and layout are impressive, giving my brand a professional and polished look. 
    </div>
    <div class="image-text-section">
        <div class="image-section">
            <img src="./../../../../assets/images/testimonial/vijay_krishna.svg" alt="person" />
        </div>
        <div class="text-section">
            Vijay Krishna
        </div>
    </div>
</section>
